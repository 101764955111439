function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-boxshadow-canvas-cssanimations-cssgradients-cssgrid_cssgridlegacy-csstransforms-csstransforms3d-cssvhunit-flexbox-flexboxlegacy-inlinesvg-svg-svgasimg-svgclippaths-touchevents-video-addtest-setclasses-shiv-teststyles !*/
!function (e, t, n) {
  function r(e, t) {
    return _typeof(e) === t;
  }

  function o() {
    var e, t, n, o, a, i, s;

    for (var l in C) {
      if (C.hasOwnProperty(l)) {
        if (e = [], t = C[l], t.name && (e.push(t.name.toLowerCase()), t.options && t.options.aliases && t.options.aliases.length)) for (n = 0; n < t.options.aliases.length; n++) {
          e.push(t.options.aliases[n].toLowerCase());
        }

        for (o = r(t.fn, "function") ? t.fn() : t.fn, a = 0; a < e.length; a++) {
          i = e[a], s = i.split("."), 1 === s.length ? Modernizr[s[0]] = o : (!Modernizr[s[0]] || Modernizr[s[0]] instanceof Boolean || (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])), Modernizr[s[0]][s[1]] = o), x.push((o ? "" : "no-") + s.join("-"));
        }
      }
    }
  }

  function a(e) {
    var t = b.className,
        n = Modernizr._config.classPrefix || "";

    if (E && (t = t.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + n + "no-js(\\s|$)");
      t = t.replace(r, "$1" + n + "js$2");
    }

    Modernizr._config.enableClasses && (t += " " + n + e.join(" " + n), E ? b.className.baseVal = t : b.className = t);
  }

  function i(e, t) {
    if ("object" == _typeof(e)) for (var n in e) {
      _(e, n) && i(n, e[n]);
    } else {
      e = e.toLowerCase();
      var r = e.split("."),
          o = Modernizr[r[0]];
      if (2 == r.length && (o = o[r[1]]), "undefined" != typeof o) return Modernizr;
      t = "function" == typeof t ? t() : t, 1 == r.length ? Modernizr[r[0]] = t : (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = t), a([(t && 0 != t ? "" : "no-") + r.join("-")]), Modernizr._trigger(e, t);
    }
    return Modernizr;
  }

  function s() {
    return "function" != typeof t.createElement ? t.createElement(arguments[0]) : E ? t.createElementNS.call(t, "http://www.w3.org/2000/svg", arguments[0]) : t.createElement.apply(t, arguments);
  }

  function l(t, n, r) {
    var o;

    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, t, n);
      var a = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));else if (a) {
        var i = a.error ? "error" : "log";
        a[i].call(a, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !n && t.currentStyle && t.currentStyle[r];

    return o;
  }

  function c(e, t) {
    return e - 1 === t || e === t || e + 1 === t;
  }

  function u() {
    var e = t.body;
    return e || (e = s(E ? "svg" : "body"), e.fake = !0), e;
  }

  function d(e, n, r, o) {
    var a,
        i,
        l,
        c,
        d = "modernizr",
        f = s("div"),
        p = u();
    if (parseInt(r, 10)) for (; r--;) {
      l = s("div"), l.id = o ? o[r] : d + (r + 1), f.appendChild(l);
    }
    return a = s("style"), a.type = "text/css", a.id = "s" + d, (p.fake ? p : f).appendChild(a), p.appendChild(f), a.styleSheet ? a.styleSheet.cssText = e : a.appendChild(t.createTextNode(e)), f.id = d, p.fake && (p.style.background = "", p.style.overflow = "hidden", c = b.style.overflow, b.style.overflow = "hidden", b.appendChild(p)), i = n(f, e), p.fake ? (p.parentNode.removeChild(p), b.style.overflow = c, b.offsetHeight) : f.parentNode.removeChild(f), !!i;
  }

  function f(e, t) {
    return !!~("" + e).indexOf(t);
  }

  function p(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, t, n) {
      return t + n.toUpperCase();
    }).replace(/^-/, "");
  }

  function m(e, t) {
    return function () {
      return e.apply(t, arguments);
    };
  }

  function h(e, t, n) {
    var o;

    for (var a in e) {
      if (e[a] in t) return n === !1 ? e[a] : (o = t[e[a]], r(o, "function") ? m(o, n || t) : o);
    }

    return !1;
  }

  function g(e) {
    return e.replace(/([A-Z])/g, function (e, t) {
      return "-" + t.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }

  function v(t, r) {
    var o = t.length;

    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) {
        if (e.CSS.supports(g(t[o]), r)) return !0;
      }

      return !1;
    }

    if ("CSSSupportsRule" in e) {
      for (var a = []; o--;) {
        a.push("(" + g(t[o]) + ":" + r + ")");
      }

      return a = a.join(" or "), d("@supports (" + a + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == l(e, null, "position");
      });
    }

    return n;
  }

  function y(e, t, o, a) {
    function i() {
      c && (delete M.style, delete M.modElem);
    }

    if (a = r(a, "undefined") ? !1 : a, !r(o, "undefined")) {
      var l = v(e, o);
      if (!r(l, "undefined")) return l;
    }

    for (var c, u, d, m, h, g = ["modernizr", "tspan", "samp"]; !M.style && g.length;) {
      c = !0, M.modElem = s(g.shift()), M.style = M.modElem.style;
    }

    for (d = e.length, u = 0; d > u; u++) {
      if (m = e[u], h = M.style[m], f(m, "-") && (m = p(m)), M.style[m] !== n) {
        if (a || r(o, "undefined")) return i(), "pfx" == t ? m : !0;

        try {
          M.style[m] = o;
        } catch (y) {}

        if (M.style[m] != h) return i(), "pfx" == t ? m : !0;
      }
    }

    return i(), !1;
  }

  function w(e, t, n, o, a) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
        s = (e + " " + D.join(i + " ") + i).split(" ");
    return r(t, "string") || r(t, "undefined") ? y(s, t, o, a) : (s = (e + " " + L.join(i + " ") + i).split(" "), h(s, t, n));
  }

  function S(e, t, r) {
    return w(e, n, n, t, r);
  }

  var x = [],
      C = [],
      T = {
    _version: "3.6.0",
    _config: {
      classPrefix: "",
      enableClasses: !0,
      enableJSClass: !0,
      usePrefixes: !0
    },
    _q: [],
    on: function on(e, t) {
      var n = this;
      setTimeout(function () {
        t(n[e]);
      }, 0);
    },
    addTest: function addTest(e, t, n) {
      C.push({
        name: e,
        fn: t,
        options: n
      });
    },
    addAsyncTest: function addAsyncTest(e) {
      C.push({
        name: null,
        fn: e
      });
    }
  },
      Modernizr = function Modernizr() {};

  Modernizr.prototype = T, Modernizr = new Modernizr(), Modernizr.addTest("svg", !!t.createElementNS && !!t.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect);
  var b = t.documentElement,
      E = "svg" === b.nodeName.toLowerCase();
  E || !function (e, t) {
    function n(e, t) {
      var n = e.createElement("p"),
          r = e.getElementsByTagName("head")[0] || e.documentElement;
      return n.innerHTML = "x<style>" + t + "</style>", r.insertBefore(n.lastChild, r.firstChild);
    }

    function r() {
      var e = w.elements;
      return "string" == typeof e ? e.split(" ") : e;
    }

    function o(e, t) {
      var n = w.elements;
      "string" != typeof n && (n = n.join(" ")), "string" != typeof e && (e = e.join(" ")), w.elements = n + " " + e, c(t);
    }

    function a(e) {
      var t = y[e[g]];
      return t || (t = {}, v++, e[g] = v, y[v] = t), t;
    }

    function i(e, n, r) {
      if (n || (n = t), d) return n.createElement(e);
      r || (r = a(n));
      var o;
      return o = r.cache[e] ? r.cache[e].cloneNode() : h.test(e) ? (r.cache[e] = r.createElem(e)).cloneNode() : r.createElem(e), !o.canHaveChildren || m.test(e) || o.tagUrn ? o : r.frag.appendChild(o);
    }

    function s(e, n) {
      if (e || (e = t), d) return e.createDocumentFragment();
      n = n || a(e);

      for (var o = n.frag.cloneNode(), i = 0, s = r(), l = s.length; l > i; i++) {
        o.createElement(s[i]);
      }

      return o;
    }

    function l(e, t) {
      t.cache || (t.cache = {}, t.createElem = e.createElement, t.createFrag = e.createDocumentFragment, t.frag = t.createFrag()), e.createElement = function (n) {
        return w.shivMethods ? i(n, e, t) : t.createElem(n);
      }, e.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + r().join().replace(/[\w\-:]+/g, function (e) {
        return t.createElem(e), t.frag.createElement(e), 'c("' + e + '")';
      }) + ");return n}")(w, t.frag);
    }

    function c(e) {
      e || (e = t);
      var r = a(e);
      return !w.shivCSS || u || r.hasCSS || (r.hasCSS = !!n(e, "article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}")), d || l(e, r), e;
    }

    var u,
        d,
        f = "3.7.3",
        p = e.html5 || {},
        m = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
        h = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
        g = "_html5shiv",
        v = 0,
        y = {};
    !function () {
      try {
        var e = t.createElement("a");
        e.innerHTML = "<xyz></xyz>", u = "hidden" in e, d = 1 == e.childNodes.length || function () {
          t.createElement("a");
          var e = t.createDocumentFragment();
          return "undefined" == typeof e.cloneNode || "undefined" == typeof e.createDocumentFragment || "undefined" == typeof e.createElement;
        }();
      } catch (n) {
        u = !0, d = !0;
      }
    }();
    var w = {
      elements: p.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video",
      version: f,
      shivCSS: p.shivCSS !== !1,
      supportsUnknownElements: d,
      shivMethods: p.shivMethods !== !1,
      type: "default",
      shivDocument: c,
      createElement: i,
      createDocumentFragment: s,
      addElements: o
    };
    e.html5 = w, c(t), "object" == (typeof module === "undefined" ? "undefined" : _typeof(module)) && module.exports && (module.exports = w);
  }("undefined" != typeof e ? e : this, t);

  var _;

  !function () {
    var e = {}.hasOwnProperty;
    _ = r(e, "undefined") || r(e.call, "undefined") ? function (e, t) {
      return t in e && r(e.constructor.prototype[t], "undefined");
    } : function (t, n) {
      return e.call(t, n);
    };
  }(), T._l = {}, T.on = function (e, t) {
    this._l[e] || (this._l[e] = []), this._l[e].push(t), Modernizr.hasOwnProperty(e) && setTimeout(function () {
      Modernizr._trigger(e, Modernizr[e]);
    }, 0);
  }, T._trigger = function (e, t) {
    if (this._l[e]) {
      var n = this._l[e];
      setTimeout(function () {
        var e, r;

        for (e = 0; e < n.length; e++) {
          (r = n[e])(t);
        }
      }, 0), delete this._l[e];
    }
  }, Modernizr._q.push(function () {
    T.addTest = i;
  }), Modernizr.addTest("svgasimg", t.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1"));
  var P = T._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];
  T._prefixes = P, Modernizr.addTest("video", function () {
    var e = s("video"),
        t = !1;

    try {
      t = !!e.canPlayType, t && (t = new Boolean(t), t.ogg = e.canPlayType('video/ogg; codecs="theora"').replace(/^no$/, ""), t.h264 = e.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, ""), t.webm = e.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/^no$/, ""), t.vp9 = e.canPlayType('video/webm; codecs="vp9"').replace(/^no$/, ""), t.hls = e.canPlayType('application/x-mpegURL; codecs="avc1.42E01E"').replace(/^no$/, ""));
    } catch (n) {}

    return t;
  }), Modernizr.addTest("cssgradients", function () {
    for (var e, t = "background-image:", n = "gradient(linear,left top,right bottom,from(#9f9),to(white));", r = "", o = 0, a = P.length - 1; a > o; o++) {
      e = 0 === o ? "to " : "", r += t + P[o] + "linear-gradient(" + e + "left top, #9f9, white);";
    }

    Modernizr._config.usePrefixes && (r += t + "-webkit-" + n);
    var i = s("a"),
        l = i.style;
    return l.cssText = r, ("" + l.backgroundImage).indexOf("gradient") > -1;
  }), Modernizr.addTest("canvas", function () {
    var e = s("canvas");
    return !(!e.getContext || !e.getContext("2d"));
  }), Modernizr.addTest("inlinesvg", function () {
    var e = s("div");
    return e.innerHTML = "<svg/>", "http://www.w3.org/2000/svg" == ("undefined" != typeof SVGRect && e.firstChild && e.firstChild.namespaceURI);
  });
  var N = "CSS" in e && "supports" in e.CSS,
      j = ("supportsCSS" in e);
  Modernizr.addTest("supports", N || j);
  var z = {}.toString;
  Modernizr.addTest("svgclippaths", function () {
    return !!t.createElementNS && /SVGClipPath/.test(z.call(t.createElementNS("http://www.w3.org/2000/svg", "clipPath")));
  });
  var k = T.testStyles = d;
  Modernizr.addTest("touchevents", function () {
    var n;
    if ("ontouchstart" in e || e.DocumentTouch && t instanceof DocumentTouch) n = !0;else {
      var r = ["@media (", P.join("touch-enabled),("), "heartz", ")", "{#modernizr{top:9px;position:absolute}}"].join("");
      k(r, function (e) {
        n = 9 === e.offsetTop;
      });
    }
    return n;
  }), k("#modernizr { height: 50vh; }", function (t) {
    var n = parseInt(e.innerHeight / 2, 10),
        r = parseInt(l(t, null, "height"), 10);
    Modernizr.addTest("cssvhunit", c(r, n));
  });
  var F = "Moz O ms Webkit",
      D = T._config.usePrefixes ? F.split(" ") : [];
  T._cssomPrefixes = D;
  var L = T._config.usePrefixes ? F.toLowerCase().split(" ") : [];
  T._domPrefixes = L;
  var $ = {
    elem: s("modernizr")
  };

  Modernizr._q.push(function () {
    delete $.elem;
  });

  var M = {
    style: $.elem.style
  };
  Modernizr._q.unshift(function () {
    delete M.style;
  }), T.testAllProps = w, T.testAllProps = S, Modernizr.addTest("cssanimations", S("animationName", "a", !0)), Modernizr.addTest("boxshadow", S("boxShadow", "1px 1px", !0)), Modernizr.addTest("cssgridlegacy", S("grid-columns", "10px", !0)), Modernizr.addTest("cssgrid", S("grid-template-rows", "none", !0)), Modernizr.addTest("flexboxlegacy", S("boxDirection", "reverse", !0)), Modernizr.addTest("flexbox", S("flexBasis", "1px", !0)), Modernizr.addTest("csstransforms", function () {
    return -1 === navigator.userAgent.indexOf("Android 2.") && S("transform", "scale(1)", !0);
  }), Modernizr.addTest("csstransforms3d", function () {
    return !!S("perspective", "1px", !0);
  }), o(), a(x), delete T.addTest, delete T.addAsyncTest;

  for (var A = 0; A < Modernizr._q.length; A++) {
    Modernizr._q[A]();
  }

  e.Modernizr = Modernizr;
}(window, document); // check modernizr only for xs devices

Modernizr.addTest('device_xs', function () {
  return window.innerWidth < 768;
}); // check modernizr only for sm devices

Modernizr.addTest('device_sm', function () {
  return window.innerWidth > 767 && window.innerWidth < 992;
}); // check modernizr from xs to sm

Modernizr.addTest('device_xs_sm', function () {
  return window.innerWidth < 992;
});
Modernizr.addTest('edge', function () {
  return /Edge/.test(navigator.userAgent);
});
Modernizr.addTest('ie11', function () {
  return !!window.MSInputMethodContext && !!document.documentMode;
});
Modernizr.addTest('retina', function () {
  return isRetinaDisplay();
});

function isRetinaDisplay() {
  if (window.matchMedia) {
    var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
    return mq && mq.matches || window.devicePixelRatio > 1;
  }
}