import '../scss/main.scss';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
/* Your JS Code goes here */
// addEventListener(terminationEvent, (event) => {
//   // Note: if the browser is able to cache the page, `event.persisted`
//   // is `true`, and the state is frozen rather than terminated.
// }, {capture: true});

/* Demo JS */

import './main.js';