function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import $ from 'jquery';
import Modernizr from './vendors/modernizr';

Number.isNaN = Number.isNaN || function (value) {
  return typeof value === 'number' && isNaN(value);
};

import Swiper, { Navigation, Pagination, Autoplay, Scrollbar, Lazy, EffectFade, Parallax, Controller } from 'swiper';
import 'selectric';
import Cookies from 'js-cookie';
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Lazy, EffectFade, Parallax, Controller]);
import lozad from 'lozad';
import './main-scripts/sk_scrollTo';
import './main-scripts/pop-blocks.js'; //const jquery = require("jquery");

var jQuery = require("jquery");

window.jQuery = window.$ = $;

var fancybox = require("@fancyapps/fancybox");

import smoothScrollbar from 'smooth-scrollbar';
import { showPopBox, hidePopBox } from './main-scripts/pop-blocks';

var $script = require("scriptjs");

var terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';
var $document = $(document);
export var windowHeight = window.innerHeight;
import Inputmask from "inputmask";
import 'jquery.inputmask';
import 'jquery-validation'; // eslint-disable-next-line no-console
//console.log(demo());
// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('append')) {
      return;
    }

    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        var argArr = Array.prototype.slice.call(arguments),
            docFrag = document.createDocumentFragment();
        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });
        this.appendChild(docFrag);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

var observer = lozad(); // lazy loads elements with default selector as '.lozad'

observer.observe(); //mobile index slider images

if (Modernizr.device_xs) {
  $('.index-main-slider .swiper-lazy').each(function () {
    var xsSrc = $(this).data("xs");
    $(this).attr("data-src", xsSrc);
  });
  $(".mobile-menu .mob-nav").append($(".main-nav"));
  var mobileContacts = $("header .contact-col").clone();
  $(".mobile-menu .adr-row").append(mobileContacts);
  $(".blog-list-categories .active a").click(function (e) {
    e.preventDefault();
    $(this).closest("ul").toggleClass("actived");
  });
}

var mobileMenuOpened = false;
$(".js_mobileBtn").on("click", function () {
  $("body").toggleClass("menu-opened");
  $(this).toggleClass("btn-actived");

  if (mobileMenuOpened === false) {
    mobileMenuOpened = true;
    $(".mobile-menu").slideDown("fast", function () {});
  } else {
    $(".mobile-menu").slideUp("fast");
    mobileMenuOpened = false;
  }
});

if (!Modernizr.device_xs && Modernizr.retina) {
  $('.index-main-slider .swiper-lazy').each(function () {
    var retinaSrc = $(this).data("retina");
    $(this).attr("data-src", retinaSrc);
  });
} //top index slider


$(".index-main-slider").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 1,
    loop: false,
    grabCursor: false,
    centeredSlides: false,
    parallax: true,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    watchSlidesVisibility: true,
    // followFinger:false,
    effect: 'fade',
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    pagination: {
      el: $(this).find(".swiper-pagination")[0]
    }
  });
  mySwiper.on('lazyImageReady', function () {
    // if (isFirstLoaded == false) {
    //
    //   isFirstLoaded = true;
    // }
    loadOtherPageImages();
  });
});
$(function () {
  var mySwiper = new Swiper(document.querySelector('.high-diseases-swiper'), {
    // Optional parameters
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: document.querySelector('.high-diseases .swiper-button-prev'),
      prevEl: document.querySelector('.high-diseases .swiper-button-next')
    },
    breakpoints: {
      960: {
        slidesPerView: 4
      }
    }
  });
  var insGallerySlider = new Swiper(document.querySelector('.ins-gallery-swiper'), {
    // Optional parameters
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: document.querySelector('.ins-gallery .swiper-button-prev'),
      prevEl: document.querySelector('.ins-gallery .swiper-button-next')
    },
    breakpoints: {
      960: {
        slidesPerView: 5
      }
    }
  });
});

function loadOtherPageImages() {
  $("picture.loadAfter").each(function () {
    $(this).find("source").each(function () {
      var srcset = $(this).data("srcset");
      $(this).attr("srcset", srcset);
    });
    var src = $(this).data("src");
    $(this).attr("src", src);
  });
}

$(".doctors-carousel").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var perGroup = 3;

  if (Modernizr.device_xs) {
    perGroup = 1;
  }

  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: perGroup,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    pagination: {
      el: $(this).find(".swiper-pagination")[0]
    }
  });
}); //Записаться с выбором врача

$(".jsDoctorZapisBtn").on("click", function () {
  var doctorID = +$(this).data("doctor-id");
  console.log(doctorID);

  if (doctorID) {
    $('.jsDoctorsSelect').val(doctorID).change();
    $('.jsDoctorsSelect').selectric('refresh');
    $('.js-consult-doctor').show();
  }
});
$('.free-consult-btn').on('click', function () {
  $('.js-consult-doctor').hide();
  $('.jsDoctorsSelect').val(0);
}); //consultation window
//validation

jQuery.validator.addMethod("maskPhone", function (value, element) {
  if ($(element).inputmask("isComplete")) {
    return true;
  } else {
    return false;
  }
}, "Неверный номер телефона!!!"); //test

$(".jsConsultForm").each(function () {
  var form = $(this);
  var maskPhone = form.find(".jsMaskPhone");
  maskPhone.inputmask("+7 (999) 999-99-99");
  form.find('select[required]').on('selectric-select', function (event, element, selectric) {
    //console.log("xselected");
    $(element).closest(".js_elemWrap").removeClass("error");
  });
  jQuery.validator.addMethod("selectric", function (value, element) {
    //console.log(value);
    if (value) {
      return true;
    } else {
      return false;
    }

    ;
  }, "Выберите значение"); //MAIN VALIDATE FUNCTION FOR CREATION FORM

  form.validate({
    rules: {
      phone: {
        maskPhone: true // <-  declare the rule someplace!

      },
      city: {
        selectric: true // <-  declare the rule someplace!

      },
      doc: {
        selectric: true // <-  declare the rule someplace!

      }
    },
    errorPlacement: function errorPlacement(error, element) {
      element.addClass("error").removeClass("valid");
      element.closest(".js_elemWrap").addClass("error").removeClass("valid");
      element.closest(".js_elemWrap").find(".jsErrorLabel").text(error.text());
    },
    highlight: function highlight(element) {
      $(element).closest(".js_elemWrap").addClass("error").removeClass("valid");
    },
    unhighlight: function unhighlight(element, errorClass, validClass) {
      $(element).closest(".js_elemWrap").removeClass("error").addClass("valid");
    },
    onsubmit: false
  });
  form.submit(function (event) {
    //console.log("Form validation status: " + form.valid());
    event.preventDefault(); //if form is valid and user submited, then we show to user next form

    if (form.valid()) {
      if ($(form).closest(".jsFormModal").length) {
        submitForm(form, true);
      } else {
        submitForm(form);
      }
    } else {
      form.addClass("not-valid-form").removeClass("valid-form"); //console.log("форма заполнена неправильно")
    }
  });
});
jQuery.extend(jQuery.validator.messages, {
  required: "Это обязательное поле.",
  remote: "Please fix this field.",
  email: "Введите действительный email адрес",
  url: "Please enter a valid URL.",
  date: "Please enter a valid date.",
  dateISO: "Please enter a valid date (ISO).",
  number: "Please enter a valid number.",
  digits: "Please enter only digits.",
  creditcard: "Please enter a valid credit card number.",
  equalTo: "Please enter the same value again.",
  accept: "Please enter a value with a valid extension.",
  maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
  minlength: jQuery.validator.format("Please enter at least {0} characters."),
  rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
  range: jQuery.validator.format("Please enter a value between {0} and {1}."),
  max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
  min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
});
$(".jsContactForm").each(function () {
  var form = $(this);
  var maskPhone = form.find(".jsMaskPhone");
  maskPhone.inputmask("+7 (999) 999-99-99"); //MAIN VALIDATE FUNCTION FOR CREATION FORM

  form.validate({
    rules: {
      phoneContacts: {
        maskPhone: true,
        // <-  declare the rule someplace!
        required: true
      },
      emailContact: {
        required: true
      }
    },
    highlight: function highlight(element) {
      $(element).closest(".js_elemWrap").addClass("error").removeClass("valid");
    },
    unhighlight: function unhighlight(element, errorClass, validClass) {
      $(element).closest(".js_elemWrap").removeClass("error").addClass("valid");
    },
    errorPlacement: function errorPlacement(error, element) {
      element.addClass("error").removeClass("valid");
      element.closest(".js_elemWrap").addClass("error").removeClass("valid");
      error.insertBefore(element);
    },
    onsubmit: true
  });
  form.submit(function (event) {
    console.log("Form validation status: " + form.valid());
    event.preventDefault(); //if form is valid and user submited, then we show to user next form

    if (form.valid()) {
      if ($(form).closest(".jsFormModal").length) {
        submitForm(form, true);
      } else {
        submitForm(form);
      }
    } else {
      form.addClass("not-valid-form").removeClass("valid-form");
    }
  });
});
$(".jsSubscribeForm").each(function () {
  var form = $(this); //MAIN VALIDATE FUNCTION FOR CREATION FORM

  form.validate({
    rules: {
      subscribeEmail: {
        required: true
      }
    },
    errorPlacement: function errorPlacement(error, element) {
      element.addClass("error").removeClass("valid");
      element.closest(".js_elemWrap").addClass("error").removeClass("valid");
      error.insertBefore(element);
    },
    onsubmit: true
  });
  form.submit(function (event) {
    event.preventDefault();

    if (form.valid()) {
      demoFormSubmit(form);
      form.removeClass("not-valid-form");
    } else {
      form.addClass("not-valid-form").removeClass("valid-form");
    }
  });
});

function demoFormSubmit(element) {
  element.addClass("sended");
}

$('[data-fancybox]').fancybox({
  hash: false
});
$(".sliderWithThumbs").each(function () {
  var mainSliderContainer = $(this).find(".jsMainSwiper");
  var sliderThumbsContainer = $(this).find(".jsThumbSwiper");
  var thumbsList = $(this).find(".jsMainThumbs");

  if (sliderThumbsContainer.length) {
    var swiperThumbs = new Swiper(sliderThumbsContainer[0], {
      slidesPerView: 'auto',
      centeredSlides: false,
      slideToClickedSlide: true,
      loop: false
    });
    swiperThumbs.on('slideChange', function () {
      var sliderRealIndex = swiperThumbs.realIndex;
    });

    if (swiperThumbs.slides.length < 2) {
      sliderThumbsContainer.addClass("noThumbs");
    }
  }

  sliderThumbsContainer.find(".swiper-slide").click(function () {
    var thisRealIndex = $(this).index();
    swiperFull.slideTo(thisRealIndex);
  });

  if (thumbsList.length) {
    thumbsList.find(".swiper-slide").click(function () {
      var thisRealIndex = $(this).index();
      swiperFull.slideTo(thisRealIndex);
    });
  }

  var swiperFull = new Swiper(mainSliderContainer[0], {
    centeredSlides: true,
    slidesPerView: 1,
    loop: false,
    effect: 'fade',
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    fadeEffect: {
      crossFade: false
    },
    controller: {
      control: swiperThumbs
    }
  });
  swiperFull.on('slideChange', function () {
    var sliderRealIndex = swiperFull.realIndex;

    if (sliderThumbsContainer.length) {
      sliderThumbsContainer.find(".swiper-slide").eq(sliderRealIndex).addClass("active").siblings().removeClass("active");
    }

    if (thumbsList.length) {
      thumbsList.find(".swiper-slide").eq(sliderRealIndex).addClass("active").siblings().removeClass("active");
    }
  });
});
$(".jsDiseasesCarousel").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var perGroup = 3;

  if (Modernizr.device_xs) {
    perGroup = 1;
  }

  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: perGroup,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    pagination: {
      el: $(this).find(".swiper-pagination")[0]
    }
  });
});
$(".jsReviewsCarousel").each(function () {
  var thisCarouselBox = $(this);
  var swiperContainer = $(this).find(".swiper-container");
  var perGroup = 2;

  if (Modernizr.device_xs) {
    perGroup = 1;
  }

  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: perGroup,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: thisCarouselBox.find(".swiper-button-next")[0],
      prevEl: thisCarouselBox.find(".swiper-button-prev")[0]
    },
    pagination: {
      el: thisCarouselBox.find(".swiper-pagination")[0]
    }
  });
});
$(".jsTalksCarousel").each(function () {
  var thisCarouselBox = $(this);
  var swiperContainer = $(this).find(".swiper-container");
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 1,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    pagination: {
      el: thisCarouselBox.find(".swiper-pagination")[0]
    }
  });
  thisCarouselBox.find(".swiper-button-next").on("click", function () {
    mySwiper.slideNext();
  });
  thisCarouselBox.find(".swiper-button-prev").on("click", function () {
    mySwiper.slidePrev();
  });
}); //liceneces slider

$(".jsLiceneseSwiper").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: 2,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    scrollbar: {
      el: $(this).find(".swiper-scrollbar")[0]
    }
  });
});
$('.jsExpandQuatroServices').on("click", function () {
  $(this).toggleClass("actived");
  $(".services-quatros-list").toggleClass("expanded");
}); //diseases tizer tabs

$('.diseases-tabs-list a').on('shown.bs.tab', function (e) {
  var targetTab = $(e.currentTarget.hash).find(".swiper-container");
  var swiperInstanse = targetTab[0].swiper;
  setTimeout(function () {
    swiperInstanse.update();
  }, 300);
});
$(".jsMap").each(function () {
  var thisMap = $(this);
  var isLoaded = false;
  var mapID = $(this).attr("id");
  var mapOffsetTop = $(this).offset().top - windowHeight;
  $document.scroll(function () {
    if (!isLoaded) {
      if ($document.scrollTop() > mapOffsetTop) {
        import('./myMap.js').then(function (module) {
          $script("//maps.googleapis.com/maps/api/js?key=AIzaSyDEeIUZg7LMGmyJcoVbvzQzSVbsEWbm9FE", function () {
            module.tizerMap(thisMap, false);
          });
        });
        isLoaded = true;
      }
    } // prepare header

  });
});
$(".jsMapContact").each(function () {
  var thisMap = $(this);
  var isLoaded = false;
  var mapID = $(this).attr("id");
  var mapOffsetTop = $(this).offset().top - windowHeight;
  import('./myMap.js').then(function (module) {
    $script("//maps.googleapis.com/maps/api/js?key=AIzaSyDEeIUZg7LMGmyJcoVbvzQzSVbsEWbm9FE", function () {
      module.tizerMap(thisMap, true);
    });
  });
}); //selectric (custom style for select) http://selectric.js.org/index.html

if ($(".js_selectCustom").length) {
  $('select.js_selectCustom').selectric({
    expandToItemText: true,
    disableOnMobile: false,
    onChange: function onChange(element) {
      $(element).closest(".selectric-custom-select").addClass("selectric-changed").find(".selectric").addClass('select-changed');
      $(element).trigger("change");
    },
    onOpen: function onOpen(element) {
      var elemScrolldiv = $(element).closest(".selectric-wrapper").find('.selectric-scroll');

      if (elemScrolldiv.length) {
        scrollerUpdate(elemScrolldiv);
      }
    }
  });
} // styling for custom scroll  http://manos.malihu.gr/jquery-custom-content-scroller/


$(".selectric-scroll").each(function () {
  var fakeScroll = $(this);
  var scrollbarSlider = smoothScrollbar.init(fakeScroll[0], {
    damping: 0.07,
    continuousScrolling: false
  });
});
$("._gCustomScroll").each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;

  if ($(this).data("continium")) {
    isContinious = true;
  }

  itemScrollbar = smoothScrollbar.init(scrollElement[0], {
    continuousScrolling: isContinious
  });
  scrollerUpdate($(this));
});
$("._gCustomScrollReview").each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;

  if ($(this).data("continium")) {
    isContinious = true;
  }

  if (!Modernizr.device_xs) {
    itemScrollbar = smoothScrollbar.init(scrollElement[0], {
      continuousScrolling: isContinious
    });
  }
});
export function scrollerUpdate(scroller) {
  var scroll = smoothScrollbar.get(scroller[0]);
  scroll.update();
  var scrollSizes = scroll.getSize();

  if (scrollSizes.container.height < scrollSizes.content.height) {
    scroller.addClass("hasScroll");
  } else {
    scroller.removeClass("hasScroll");
  }
}

function consultFormSubmitDemo(formElement) {
  var thisModalContent = formElement.closest(".modal-content");
  thisModalContent.addClass("is-loading");
  setTimeout(function () {
    thisModalContent.removeClass("is-loading");
    thisModalContent.addClass("success-send");
    setTimeout(function () {
      thisModalContent.removeClass("success-send");

      if (formElement.closest(".pop-block").length) {
        hidePopBox(formElement.closest(".pop-block"));
      }

      if (formElement.closest(".modal").length) {
        formElement.closest(".modal").modal('hide');
      }
    }, 2500);
  }, 1000);
} //demo box


$(".jsDemoSubmit").on("click", function () {
  consultFormSubmitDemo($(this).closest("form"));
}); //header search toggle

$(".jsHeadSearchToggle").on("click", function (e) {
  if (!$(this).closest(".jsSearchWrapper").hasClass("showedSearch")) {
    if (!$(this).closest(".site-header").hasClass("pinned")) {
      e.preventDefault();
      $('.site-header .jsSearchWrapper').toggleClass("showedSearch");
      $('site-header .jsSearchWrapper input').focus();
    }
  }
}); //fixed header on screen scroll

var $navi = $(".site-header");
$document.scroll(function () {
  // prepare header
  if ($document.scrollTop() < 400) {
    $navi.removeClass("prepareHeader");
  }

  if ($document.scrollTop() >= 400) {
    $navi.addClass("prepareHeader");
  }

  if ($document.scrollTop() < 800) {
    $navi.removeClass("pinned");
  }

  if ($document.scrollTop() >= 800) {
    $navi.addClass("pinned");
  } //hide nav when no scroll

});
$(".jsExpandLetter").on("click", function () {
  $(this).closest(".letter").toggleClass("opened");
}); // review form active

$('#addReviewForm').on('show.bs.collapse', function () {
  $(".rev-action-row").addClass("active");
});
$('#addReviewForm').on('hide.bs.collapse', function () {
  $(".rev-action-row").removeClass("active");
});
$(".jsToggler").click(function () {
  var thisBtn = $(this);
  var toggleTarget = $(thisBtn.data("elem"));
  toggleTarget.toggleClass("actived-element");
  thisBtn.toggleClass("actived");
}); //blog content slider

$(".jsContentSwiper").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    pagination: {
      el: $(this).find(".swiper-pagination")[0]
    }
  });
}); //blog content slider

$(".jsRelatedPostsSwiper").each(function () {
  var swiperContainer = $(this).find(".swiper-container");
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    navigation: {
      nextEl: $(this).find(".swiper-button-next")[0],
      prevEl: $(this).find(".swiper-button-prev")[0]
    },
    pagination: {
      el: $(this).find(".swiper-pagination")[0]
    }
  });
}); // review modal
// В двух словах:  У нас есть одно окно отзыва, мы просто берем полный текст с выводимого отзыва и вставляем его в это окно по частям.
//Сделано чтобы было хорошее сео и оптимизация загрузки страницы (чтобы не было лишнего мусора в коде)

$(".jsRevMore ").on("click", function (e) {
  var reviewModal = $("#reviewModal");
  var shortRev = $(this).closest(".jsShortRev");
  var revName = shortRev.find(".jsRevName").html();
  var revText = shortRev.find(".jsRevText").html();
  var revInfo = shortRev.find(".jsRevInfo").html();
  reviewModal.find(".jsRevName").append(revName);
  reviewModal.find(".jsRevText").append(revText);
  reviewModal.find(".jsRevInfo").append(revInfo);
  e.preventDefault();
  setTimeout(function () {
    reviewModal.modal('show');
  }, 100);
}); //diseases tizer tabs

$('#reviewModal').on('show.bs.modal', function (e) {
  // var thisModal = $(this)
  // e.preventDefault();
  //
  var elemScrolldiv = $("#reviewModal").find('._gCustomScrollReview');
  setTimeout(function () {
    if (!Modernizr.device_xs) {
      scrollerUpdate(elemScrolldiv);
    }

    $("#reviewModal .jsRevLoading").fadeOut("fast");
  }, 300);
});
$('#reviewModal').on('hidden.bs.modal', function (e) {
  cleanModalReview();
});

function cleanModalReview() {
  var reviewModal = $("#reviewModal");
  reviewModal.find(".jsRevName").text("");
  reviewModal.find(".jsRevText").text("");
  reviewModal.find(".jsRevInfo").text("");
  $("#reviewModal .jsRevLoading").fadeIn("fast");
} //specialists expand on mobile


$(".jsExpandAllSpecsBtn").on("click", function () {
  $(this).closest(".specialists-row").toggleClass("active");
  $(this).toggleClass("collapsed");
});
$(".jsXsCollapse .xs-collapse-btn").on("click", function (e) {
  $(this).toggleClass("active");
  var target = $($(this).attr("href"));
  target.slideToggle("fast").toggleClass("active show");
  e.preventDefault();
});
$('.jsDeskTabs a[data-toggle="tab"]').on('show.bs.tab', function (e) {
  $("#specialistTabContent .tab-pane").removeClass("show active");
});

if (Modernizr.device_xs) {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px'); // YOUR XS CODE HERE...
}

$('.js-filter-form').on("change", "select", function (e) {
  $(this).closest("form").submit();
});
var $html = $("html"),
    isBusyAjax = false;
$html.on("click", '[data-show-more] a', function (e) {
  e.preventDefault();
  var $this = $(this),
      $wrapNavi = $this.parent("[data-show-more]"),
      blockId = $wrapNavi.data("show-more"),
      $wrapList = $html.find('[data-more-wrap = "' + blockId + '"]');

  if (!isBusyAjax) {
    $.ajax({
      type: "GET",
      url: $this.attr("href"),
      beforeSend: function beforeSend() {
        $wrapNavi.html('<div class="loader"><img class="img-responsive"' + ' src="/local/templates/tibet2021/assets/images/logo.png" alt=""></div>');
        isBusyAjax = true;
      },
      success: function success(data) {
        var $data = $(data);
        $wrapList.append($data.find('[data-more-wrap = "' + blockId + '"]').html());
        $wrapNavi.html($data.find('[data-show-more = "' + blockId + '"]').html());
        isBusyAjax = false;
        observer.observe();
      },
      error: function error() {
        console.log("ERROR request");
        isBusyAjax = false;
      },
      dataType: 'html'
    });
  }
});
var $reviewModal = $("#reviewModal"),
    $reviewWrap = $(".js-review-wrap"); // review modal

$html.on("click", '.js-show-review', function (e) {
  e.preventDefault();
  var $this = $(this),
      reviewId = +$this.data("review-id");

  if (!isBusyAjax && reviewId) {
    $.ajax({
      type: "GET",
      url: '/local/ajax/review/',
      data: {
        ID: reviewId
      },
      beforeSend: function beforeSend() {
        $reviewModal.modal('show');
        isBusyAjax = true;
      },
      success: function success(data) {
        $reviewWrap.html(data);

        if (!Modernizr.device_xs) {
          smoothScrollbar.init($reviewWrap.find('._gCustomScrollReview')[0], {
            continuousScrolling: false
          });
        }

        $("#reviewModal .jsRevLoading").fadeOut("fast");
        isBusyAjax = false;
      },
      error: function error() {
        console.log("ERROR request");
        $reviewModal.close();
        isBusyAjax = false;
      },
      dataType: 'html'
    });
  }
});
$reviewModal.on('hidden.bs.modal', function (e) {
  $reviewWrap.html('<div class="jsRevLoading"></div>');
});

function submitForm($form) {
  var isModal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var action = $form.attr("action"),
      goalYa = $form.data("goal-ya"),
      goalGa = $form.data("goal-ga"),
      goalFbq = $form.data("goal-fbq"),
      modalContent = isModal ? $form.closest(".modal-content") : null,
      isCallbackForm = $form.hasClass("jsCallbackForm");
  grecaptcha.ready(function () {
    grecaptcha.execute(document.querySelector('meta[name="recaptcha-key"]').content, {
      action: 'submit'
    }).then(function (token) {
      document.querySelectorAll('.js-form-token').forEach(function (el) {
        el.value = token;
      });

      if (!isBusyAjax) {
        $.ajax({
          type: "POST",
          url: action,
          data: $form.serialize(),
          beforeSend: function beforeSend() {
            if (isModal) modalContent.addClass("is-loading");
            isBusyAjax = true;
          },
          success: function success(data) {
            if (isModal) modalContent.removeClass("is-loading");

            if (data.status) {
              if (isModal) {
                modalContent.addClass("success-send");
                setTimeout(function () {
                  modalContent.removeClass("success-send");

                  if (isCallbackForm) {
                    $(".modal-window").removeClass("opened");
                    Cookies.set("showFormCallBack", true, {
                      expires: 0.1
                    });
                    shownFormCallBack = true;
                  } else {
                    if ($form.closest(".pop-block").length) {
                      hidePopBox($form.closest(".pop-block"));
                    }

                    if ($form.closest(".modal").length) {
                      $form.closest(".modal").modal('hide');
                    }
                  }
                }, 2500);
              } else {
                $form.addClass("sended");
              }

              if (window.fbq && goalFbq) {
                window.fbq("track", goalFbq);
              }

              if (window.gtag && goalGa) {
                window.gtag('event', "submit", {
                  'event_category': goalGa
                });
              }

              if (window.ym && goalYa) {
                window.ym(9614266, 'reachGoal', goalYa);
              }

              $form.get(0).reset();
            } else {
              console.log(data.message);
              modalContent.addClass("error-send");
            }

            isBusyAjax = false;
          },
          error: function error() {
            console.log("ERROR request");
            isBusyAjax = false;
            modalContent.addClass("error-send");
          },
          dataType: 'json'
        });
      }
    });
  });
}

;
$('[data-yandex-goal]').on("click", function (e) {
  var goal = $(this).attr("data-yandex-goal");

  if (goal && _typeof(window.Ya) === "object") {
    window.ym(window.Ya.Metrika2.counters()[0].id, 'reachGoal', goal);
  }
});
$('[data-target="#modalConsult"]').click(function (event) {
  var bonus = window.testBonus || false;

  if (bonus) {
    window.ym(window.Ya.Metrika2.counters()[0].id, 'reachGoal', 'NEW_TEST-' + bonus);
  }
});
$(window).on("load", function () {
  if (Modernizr.device_xs) {
    $('<em></em>').insertAfter(".main-nav .with-drop > a");
    $(".main-nav .with-drop > em").click(function (e) {
      e.preventDefault();
      $(this).closest("li").siblings().removeClass('opened').find(".drop-menu").slideUp("fast");
      $(this).closest("li").toggleClass("opened").find(".drop-menu").slideToggle("fast");
    });
  } //intagram modal


  $(".insta-modal").each(function () {
    var $thisModal = $(this);
    var notiID = $(this).attr("id");
    var showTime = 4;

    if ($(this).data("show-after")) {
      showTime = $(this).data("show-after");
    }

    if (!Cookies.get(notiID)) {
      Cookies.get(notiID);
      setTimeout(function () {
        $thisModal.modal("show");
      }, showTime * 1000);
    }

    if (Modernizr.device_xs) {
      $(this).appendTo("body");
    }

    $(this).find(".jsCloseForever").on("click", function () {
      Cookies.set(notiID, true);
      $("#instaModal").removeClass("show");
      $("body").removeClass("modal-open").css("paddingRight", 0);
    });
  });
});
$(".jsCloseNoti").on("click", function (e) {
  e.preventDefault();
  var notiID = $(this).closest(".fixed-notification").attr("id");
  $(this).closest(".fixed-notification").removeClass("actived");
  Cookies.set(notiID, true, {
    expires: 31
  });
});
$(".fixed-notification").each(function () {
  var notiID = $(this).attr("id");

  if (!Cookies.get(notiID)) {
    setTimeout(function () {
      $('.fixed-notification').addClass('actived');
    }, 2000);
  }
});
var shownFormCallBack = Cookies.get("showFormCallBack"); // $(document).on("mouseleave", function () {
//   if (!shownFormCallBack) {
//     $('.modal-window').addClass('opened');
//   }
// });
// $(".modal-window").each(function () {
//   if (!shownFormCallBack && $(document).width() < 769) {
//     setTimeout(function () {
//       $('.modal-window').addClass('opened');
//     }, 30000);
//   }
// });

$(".close-modal-callback").on("click", function () {
  $(".modal-window").removeClass("opened");
  Cookies.set("showFormCallBack", true, {
    expires: 0.1
  });
  shownFormCallBack = true;
});
$(".jsCallbackForm").each(function () {
  var form = $(this);
  var maskPhone = form.find(".jsMaskPhone");
  maskPhone.inputmask("+7 (999) 999-99-99");
  form.validate({
    rules: {
      phone: {
        maskPhone: true,
        required: true
      }
    },
    errorPlacement: function errorPlacement(error, element) {
      element.addClass("error").removeClass("valid");
      element.closest(".js_elemWrap").addClass("error").removeClass("valid");
    },
    highlight: function highlight(element) {
      $(element).closest(".js_elemWrap").addClass("error").removeClass("valid");
    },
    unhighlight: function unhighlight(element, errorClass, validClass) {
      $(element).closest(".js_elemWrap").removeClass("error").addClass("valid");
    },
    onsubmit: true
  });
  form.submit(function (event) {
    event.preventDefault();

    if (form.valid()) {
      submitForm(form, true);
    }
  });
});