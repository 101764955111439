import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
$('.js_scrollToLink').click(function (e) {
  console.log("clicked");
  var speed = 1500;

  if ($(this).data("speed")) {
    speed = $(this).data("speed");
  }

  var target = $(this.hash);
  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

  if (!Modernizr.device_xs && $("#scrolled").length) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      if ($('#scrolled').length) {
        var scrolledAmount = parseInt(target.offset().top);
        var scrolledDiff = scrolledAmount + parseInt(scrollbar.offset.y);

        if (target.length) {
          scrollbar.scrollTo(0, scrolledDiff, speed, false);
        }
      }

      return false;
    }
  } else {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: parseInt(target.offset().top - 85)
    }, 'slow');
  }
});